<template>
  <div class="w-full h-full">
    <div
      class="w-full ss:max-w-xs xx:max-w-screen-xx xs:max-w-screen-md sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-4 h-full flex justify-center"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center">
          <div class="flex lg:hidden cursor-pointer" @click="toggleNavbar">
            <PtIcon
              :name="showMenu ? 'close-circle' : 'bars'"
              class="text-2xl mr-2 text-white"
              :class="showMenu"
            />
          </div>
          <a
            :href="`${spaceUrl}#sso`"
            class="flex items-center leading-relaxed whitespace-nowrap w-24 h-8"
          >
            <img class="w-24 h-8" :src="logo" alt="Plataforma Tierra" title="Plataforma Tierra" />
          </a>
        </div>
        <nav
          class="hidden h-full lg:flex flex-row relative items-center justify-between flex-grow bg-white lg:bg-gray-dark"
        >
          <ul
            class="h-full pt-8 lg:pt-0 lg:ml-7 2xl:ml-14 lg:mr-4 flex flex-col lg:flex-row h-header-mobile md:h-header-pc"
          >
            <li v-for="item in menuItems" :key="item.url" class="w-full lg:flex px-1">
              <PtMegamenu
                v-if="checkOurTools(item)"
                :item="item"
                :space-url="spaceUrl"
                :windowWidth="windowWidth"
              />
              <a
                v-else
                class="font-sans text-gray p-2 block font-semibold text-lg lg:text-base hover:text-blue h-14 lg:h-auto lg:text-white lg:px-3 lg:flex lg:items-center lg:border-gray-dark cursor-pointer"
                :href="`${spaceUrl}${item.url}#sso`"
              >
                <span v-text="item.label" />
              </a>
            </li>
          </ul>
        </nav>
        <div class="relative flex items-center">
          <Notifications />
          <div class="w-px h-8 bg-white mx-4 block"></div>
          <div
            class="flex items-center justify-center ring-0 focus:outline-none cursor-pointer"
            @click="showUserMenu = !showUserMenu"
            v-click-outside="closeShowUserMenu"
          >
            <div
              class="flex-shrink-0 h-8 w-8 rounded-full overflow-hidden flex justify-center items-center"
              :class="{
                'border bg-white border-gray-light': !user.picture && !showUserMenu,
                'blue border-2 border-blue fill-icon ': showUserMenu
              }"
            >
              <img v-if="user.picture" class="w-min" :src="user.picture" />
              <PtIcon
                v-else
                name="pt-user"
                class="w-4"
                :class="{
                  'text-gray-light': !user.picture && !showUserMenu,
                  ' fill-icon__blue ': showUserMenu
                }"
              />
            </div>
          </div>
          <div v-if="showUserMenu" class="pt-headerOverlay"></div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div
              v-if="showUserMenu"
              class="origin-top-right absolute w-48 mt-0 top-11 md:mt-48 md:top-auto right-0 rounded-b shadow-md border-solid border border-gray-very-light bg-white z-10 focus:outline-none"
            >
              <div v-if="user.name" class="block">
                <div class="flex items-center bg-gray text-white -mt-1 -mx-px">
                  <span class="mx-2 my-1.5 text-base font-sans">
                    {{ user.name }} {{ user.surname }}
                  </span>
                </div>
              </div>
              <div class="cursor-pointer" @click="editProfile">
                <div class="flex items-center py-2 border-b-2 border-gray-very-light">
                  <PtIcon name="pt-edit" class="mx-2" />
                  <span
                    class="text-gray-700 w-full text-base font-sans text-gray hover:underline"
                    >{{ profileItemsText.editProfile }}</span
                  >
                </div>
              </div>
              <div class="cursor-pointer" @click="closeSession">
                <div class="flex items-center py-2">
                  <PtIcon name="pt-exit" class="mx-2" />
                  <span
                    class="text-gray-700 w-full text-base font-sans text-gray hover:underline"
                    >{{ profileItemsText.closeSession }}</span
                  >
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ptWindow from '@/mixin/window';
import { useRoute } from 'vue-router';
import { PtIcon } from '@pt/designsystem';
import Notifications from '../Notifications';
import { PtMegamenu } from '@pt/designsystem';
export default {
  name: 'HeaderNavbar',
  components: {
    PtIcon,
    PtMegamenu,
    Notifications
  },
  directives: {
    'click-outside': {
      beforeMount(el, binding) {
        el.clickOutsideEvent = evt => {
          evt.stopPropagation();
          if (!(el === evt.target || el.contains(evt.target))) {
            binding.value(evt, el);
          }
        };
        window.requestAnimationFrame(() => {
          document.addEventListener('click', el.clickOutsideEvent);
        });
      },
      unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  props: {
    menuItems: {
      type: [Array, Object],
      default: () => []
    },
    profileItemsText: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    },
    spaceUrl: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  emits: ['closeSession', 'editProfile', 'clickMenuButton'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const showMenu = ref(false);
    const showUserMenu = ref(false);
    const { windowWidth } = ptWindow();
    const toggleNavbar = () => {
      showMenu.value = !showMenu.value;
      scrollBody(showMenu.value);
      emit('clickMenuButton');
    };
    const scrollBody = data => {
      data ? (document.body.style.overflow = 'hidden') : document.body.removeAttribute('style');
    };
    const editProfile = () => emit('editProfile');
    const closeSession = () => emit('closeSession');
    const closeShowUserMenu = () => (showUserMenu.value = false);
    const checkOurTools = item => item.label.toLowerCase() === 'herramientas';
    watch(
      () => route.path,
      () => {
        showMenu.value = false;
        document.body.removeAttribute('style');
      }
    );
    return {
      t,
      showMenu,
      scrollBody,
      editProfile,
      windowWidth,
      showUserMenu,
      closeSession,
      toggleNavbar,
      checkOurTools,
      closeShowUserMenu
    };
  }
};
</script>
<style>
:root {
  --header-distancing: 70px;
}
@media (max-width: 1023px) {
  .navbar {
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
    top: var(--header-distancing);
    height: calc(100% - var(--header-distancing));
  }
}
.visible-navbar {
  transition: 0.3s ease-in-out;
  transform: translateX(0);
}
.pt-headerOverlay {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  height: 100vh;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
  opacity: 0.75;
  top: calc(var(--header-height));
  overflow: hidden;
}
.fill-icon {
  background-color: rgb(16 157 194 / var(--tw-bg-opacity));
}
.fill-icon__blue {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
