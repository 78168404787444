<template>
  <!-- eslint-disable vue/require-toggle-inside-transition -->
  <transition name="fade" mode="out-in">
    <div :class="type" class="rounded border cursor-pointer" role="alert">
      <div class="flex">
        <div v-if="text" class="flex-1 pl-4 py-3" @click="clickBody">
          <PtIcon :color="getColorByType(type)" class="text-lg" :name="getIconByType(type)" />
          <!-- eslint-disable vue/no-v-html -->
          <span class="ml-2 bold" v-html="text" />
        </div>
        <div v-else @click="clickBody">
          <slot></slot>
        </div>

        <span class="pr-4 py-3 ml-3" @click="closeAlert">
          <PtIcon :color="getColorByType(type)" name="pt-close-circle" class="text-lg" />
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import { PtIcon } from '@pt/designsystem';
export default {
  name: 'Alert',
  components: {
    PtIcon
  },

  props: {
    type: {
      type: String,
      default: 'warning'
    },
    text: {
      type: String,
      default: ''
    }
  },
  emits: ['closeAlert', 'clickBody'],
  setup(_, { emit }) {
    const getColorByType = type => {
      const colors = {
        alert: 'red',
        warning: 'orange',
        info: 'blue'
      };
      return colors[type];
    };

    const getIconByType = type => {
      const types = {
        alert: 'pt-alert-triangle-filled',
        warning: 'pt-alert-circle-filled',
        info: 'pt-info-circle-filled'
      };
      return types[type];
    };
    const clickBody = () => {
      emit('clickBody');
    };
    const closeAlert = () => {
      emit('closeAlert');
    };

    return {
      getColorByType,
      getIconByType,
      clickBody,
      closeAlert
    };
  }
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.alert {
  background-color: #f0cdcc;
  border-color: #c23934;
  color: #c23934;
}
.warning {
  background-color: #f7eddf;
  border-color: #ed7527;
  color: #ed7527;
}
.info {
  background-color: #e7f5f9;
  border-color: #109dc2;
  color: #109dc2;
}
</style>
