<template>
  <Home />
</template>

<script>
import Home from './views/Home.vue';

export default {
  components: {
    Home
  }
};
</script>
