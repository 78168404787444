import { globalStorage } from '@pt/utilities';
import { enums } from '@pt/env-config';
import { computed } from 'vue';

export default function () {
  const getter = name => {
    return computed(() => globalStorage.get(enums[name])).value;
  };
  const setters = (name, value) => {
    return globalStorage.set(enums[name], value);
  };
  const removers = name => {
    return globalStorage.remove(enums[name]);
  };
  return {
    getter,
    setters,
    removers
  };
}
