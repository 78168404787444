<template>
  <div class="fixed w-full flex flex-col justify-center bg-gray-dark" style="z-index: 60">
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PtAlert
        v-if="show"
        class="lg:absolute h-16 lg:mx-auto lg:my-0 lg:left-0 lg:right-0 lg:top-3 z-10 cursor-pointer"
        :text="t('alert.pwa')"
        :logo="require('@/assets/logo-t.svg')"
        @openPwa="triggerPwa"
        @close="setAlert(false, 0)"
        @mouseenter="wait = true"
        @mouseleave="wait = false"
        pwa
      />
    </transition>
    <PtHeader
      class="h-header md:h-header-md"
      :menu-items="menuItems"
      :profile-items-text="profileItemsText"
      :logo="require('@/assets/logo-tierra.svg')"
      :user="user"
      :space-url="spaceUrl"
      @click-menu-button="toggleSidebar"
      @close-session="closeSession"
      @edit-profile="editProfile"
    />
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { events } from '@pt/utilities';
import storage from '../mixin/storage';
import { PtAlert } from '@pt/designsystem';
import { env, enums } from '@pt/env-config';
import PtHeader from '../components/Header';
import { api, auth, gtm } from '@pt/services';
import { ref, reactive, toRefs, computed, watch, onMounted, onUnmounted } from 'vue';
export default {
  components: {
    PtHeader,
    PtAlert
  },
  setup() {
    const { getter } = storage();
    const { t } = useI18n();
    const deferredPrompt = ref(null);
    const alert = reactive({
      timer: 0,
      show: false,
      wait: false
    });
    const user = ref({
      id: '',
      name: '',
      surname: '',
      picture: ''
    });
    const checkSW = getter(enums.STATUS_SERVICE_WORKER);
    const { timer, show, wait } = toRefs(alert);
    const profileItemsText = {
      editProfile: t('profile.editProfile'),
      closeSession: t('profile.closeSession')
    };
    const showSidebar = ref(false);
    events.on(enums.EVT_TOGGLE_SIDEBAR, conditionSidebar => {
      showSidebar.value = conditionSidebar;
    });
    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;
      events.emit(enums.EVT_TOGGLE_SIDEBAR, showSidebar.value);
    };
    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    onMounted(() => {
      if (checkSW) triggerPromptPwa();
      window.addEventListener('service-worker', triggerPromptPwa);
      window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => {
      window.removeEventListener('service-worker', triggerPromptPwa);
      window.removeEventListener('resize', onWidthChange);
    });
    const lgScreen = computed(() => {
      return windowWidth.value >= 1024;
    });
    (async () => {
      await api.getUserProfile().then(({ userProfile: { vid, name, surname, picture, uuid } }) => {
        user.value = { vid, name, surname, picture, uuid };
      });
    })();
    const closeSession = async () => {
      await auth.signOut();
    };
    const editProfile = () => {
      window.location.replace(`${env.APP_BASE_DOMAIN}${enums.PROFILE_PAGE}`);
    };
    const setAlert = (showed, time = 5000) => {
      clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        show.value = showed;
      }, time);
    };
    const checkPDA = computed(() => {
      return (
        window.navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        document.referrer.startsWith('android-app://')
      );
    });
    const checkBrowserSafari = computed(() => {
      return (
        /Safari/i.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor) &&
        !/Mobi|Android/i.test(navigator.userAgent)
      );
    });
    const triggerPromptPwa = () => {
      if (!checkPDA.value && !checkBrowserSafari.value) {
        setAlert(true);
        window.addEventListener('beforeinstallprompt', e => {
          e.preventDefault();
          deferredPrompt.value = e;
        });
      }
    };
    const triggerPwa = async () => {
      if (deferredPrompt.value !== null) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === 'accepted') {
          deferredPrompt.value = null;
          show.value = false;
        }
      }
    };
    watch(
      () => [show.value, wait.value],
      (newVal, oldVal) => {
        if (newVal[0] !== oldVal[0]) {
          document.querySelector('.main').classList.toggle('pwa-banner');
        }
        if (!newVal[1]) {
          setAlert(false, 10000);
        }
      }
    );
    watch(user, ({ vid }) => {
      if (vid) {
        // User ID for GTM
        gtm.pushUserId(vid, window.innerWidth < 1024);
        // Pushing event to GTM of the page view type the first time
        gtm.pushPageView('/', (typeof document !== 'undefined' && document.title) || '');
      }
    });
    return {
      t,
      user,
      show,
      setAlert,
      lgScreen,
      triggerPwa,
      editProfile,
      closeSession,
      toggleSidebar,
      triggerPromptPwa,
      profileItemsText,
      menuItems: enums.HEADER_ITEMS,
      spaceUrl: env.APP_BASE_DOMAIN
    };
  }
};
</script>
<style>
.pwa-banner {
  padding-top: 64px;
}
@media screen and (min-width: 1024px) {
  .pwa-banner {
    padding-top: 0;
  }
}
</style>
