<template>
  <div
    class="group notification-wrapper cursor-pointer py-4 px-2 border border-transparent hover:border-blue rounded-sm"
  >
    <div
      v-if="isCollapsibleParent"
      class="h-5"
      @click.stop.prevent="clickedNotification(!isExpanded)"
    >
      <transition v-if="showCollapsibleOptions" name="fade" mode="in-out" appear>
        <div class="flex justify-end space-x-2">
          <span
            class="text-blue text-sm font-semibold cursor-pointer hover:underline"
            @click="hideAll"
          >
            {{ $t('notifications.showMore') }}
          </span>
          <span
            class="text-blue text-sm font-semibold cursor-pointer hover:underline"
            @click="deleteAll"
          >
            {{ $t('notifications.removeAll') }}
          </span>
        </div>
      </transition>
    </div>

    <div
      class="flex justify-between"
      @mouseover="clickNotificationMobile()"
      @mouseleave="mouseleave()"
      @click.stop.prevent="clickedNotification(true)"
    >
      <div class="flex items-start">
        <div class="flex">
          <div class="flex items-center space-x-2">
            <div class="flex justify-center mt-0 sm:mt-0.5 relative h-8 w-8">
              <template v-if="(isCollapsible && isExpanded) || !isCollapsible">
                <div class="bottom-3 absolute" @click.stop.prevent="closeNotification(index)">
                  <PtIcon
                    style="font-size: 24px"
                    color="blue"
                    class="hidden group-hover:block z-10"
                    name="pt-times-circle"
                  />
                </div>

                <PtIcon
                  class="text-xl bottom-3 absolute group-hover:hidden"
                  :name="getPropByType(item.category, 'icon')"
                  :color="getPropByType(item.category, 'color')"
                />
              </template>
              <PtIcon
                v-else
                :color="getPropByType(item.category, 'color')"
                class="text-xl absolute"
                :name="getPropByType(item.category, 'icon')"
              />
            </div>
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div>
          <div>
            <span class="text-blue text font-semibold">{{ item.title }}</span>
            <span class="text-xs md:text-sm font-semibold text-gray-light ml-2">{{
              differenceBetweenDatesWithText(item.dispatchTime)
            }}</span>
          </div>
          <div class="font-ubuntu" :class="item.link || (item.body && 'max-w-sm 2xl:max-w-lg')">
            <b>{{ getPropByType(item.category, 'text') }}</b>
            <span v-if="item.resume" class="ml-1">| {{ item.resume }}</span>
          </div>
          <div v-if="item.body" class="font-ubuntu max-w-sm" v-html="`${item.body}`" />
        </div>
      </div>
      <span
        v-if="(isCollapsible && isExpanded) || !isCollapsible"
        class="font-ubuntu text-blue font-semibold min-w-max hidden group-hover:flex transition-all items-start"
      >
        <div class="flex items-center">
          <span class="hover:underline hidden lg:block">
            {{ $t('notifications.seeMore') }}
          </span>
          <PtIcon name="pt-angle-right" color="blue" class="text-lg" />
        </div>
      </span>
      <span v-if="isCollapsible && !isExpanded" class="text-blue text-sm font-semibold">
        {{ groupLength }} {{ $t('notifications.notifications') }}
      </span>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { PtIcon } from '@pt/designsystem';
import { day } from '@pt/utilities';
export default {
  name: 'PtMyEventsNavigationItem',
  props: {
    index: {
      type: Number,
      default: null
    },
    item: {
      type: Object,
      default: () => {}
    },
    showCollapsibleOptions: {
      type: Boolean,
      default: false
    },
    isCollapsibleParent: {
      type: Boolean,
      default: false
    },
    isCollapsible: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    groupLength: {
      type: Number,
      default: undefined
    }
  },
  components: {
    PtIcon
  },
  emits: ['deleteAll', 'hideAll', 'clickedNotification', 'openCollapsible', 'closeNotification'],
  setup(props, { emit }) {
    const { differenceBetweenDatesWithText } = day;
    const activeMobileNotification = ref(false);

    const isMobileScreen = computed(() => window.innerWidth <= 1024);

    const getPropByType = (type, prop) => {
      const types = {
        alert: { icon: 'pt-alert-triangle-filled', color: 'red', text: 'Alerta' },
        warning: { icon: 'pt-alert-circle-filled', color: 'orange', text: 'Aviso' },
        info: { icon: 'pt-info-circle-filled', color: 'blue', text: 'Información' },
        email: { icon: 'pt-info-circle-filled', color: 'blue', text: 'Información' }
      };
      return type && prop ? types[type][prop] : types.info[prop];
    };
    const deleteAll = () => {
      emit('deleteAll', props.item);
    };
    const hideAll = () => {
      emit('hideAll');
    };
    const closeNotification = () => {
      emit('closeNotification', props.item);
    };
    const clickNotificationMobile = () => {
      if (
        isMobileScreen.value &&
        ((props.isCollapsible && props.isExpanded) || !props.isCollapsible)
      ) {
        setTimeout(() => {
          activeMobileNotification.value = true;
        }, 100);
      }
    };
    const mouseleave = () => {
      if (isMobileScreen.value) {
        setTimeout(() => {
          activeMobileNotification.value = false;
        }, 100);
      }
    };
    const clickedNotification = control => {
      if (isMobileScreen.value) {
        if (control && activeMobileNotification.value) {
          logicNotification();
        }
        if (props.isCollapsible && !activeMobileNotification.value) {
          emit('openCollapsible');
        }
      } else if (control) {
        logicNotification();
      }
    };
    const logicNotification = () => {
      if ((props.isCollapsible && props.isExpanded) || !props.isCollapsible) {
        emit('clickedNotification', { item: props.item, index: props.index });
      } else {
        emit('openCollapsible');
      }
    };

    return {
      activeMobileNotification,
      differenceBetweenDatesWithText,
      isMobileScreen,
      getPropByType,
      deleteAll,
      hideAll,
      closeNotification,
      clickNotificationMobile,
      mouseleave,
      clickedNotification,
      logicNotification
    };
  }
};
</script>
<style src="../style.scss" lang="scss" />
