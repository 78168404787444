import { ref, onMounted } from 'vue';
import { api, auth } from '@pt/services';
import { socketIO, day } from '@pt/utilities';
import { env } from '@pt/env-config';
export default function () {
  const animated = ref(false);
  const notifications = ref([]);
  const rawNotifications = ref([]);
  const notificationAlerts = ref({ alert: [], warning: [], info: [] });
  const initListenerNotifications = async () => {
    const token = await auth.acquireToken();
    const { idTokenClaims } = auth.getActiveAccount();
    const path = env.API_HOST_NOTIFICATIONS;
    const socket = await socketIO.init(path, token);
    socket.on(idTokenClaims.sub, (data, callback) => {
      updateNotifications(JSON.parse(data));
      callback({ status: 'ok' });
    });
  };
  const activateRingAnimation = () => {
    animated.value = true;
    setTimeout(() => {
      animated.value = false;
    }, 4000);
  };
  const updateNotifications = newNotification => {
    const savedNotifications = notifications.value;
    if (!savedNotifications.some(e => e.uuid === newNotification.uuid)) {
      if (day.isLessThanOneDay(newNotification.datetime)) {
        notificationAlerts.value[newNotification.category].push(newNotification);
        activateRingAnimation();
      }
      rawNotifications.value = [...rawNotifications.value, newNotification];
      changeValueNotifications(rawNotifications.value);
    }
  };
  const changeValueNotifications = data => {
    notifications.value = getNotificationsGroupById(data);
  };
  const hideAlert = (type, hideAll = false) => {
    if (hideAll) {
      notificationAlerts.value = { alert: [], warning: [], info: [] };
    } else {
      notificationAlerts.value[type] = [];
    }
  };
  const removeNotification = async (notification, isCollapsible = false) => {
    let unReadNotifications = [];
    if (!isCollapsible) {
      unReadNotifications = rawNotifications.value.filter(e => e.uuid !== notification.uuid);
      api.setReadedNotification(notification.uuid);
    } else {
      unReadNotifications = rawNotifications.value.filter(
        e => e.group_id !== notification.group_id
      );
      api.setReadedNotificationAll(notification.group_id);
    }
    rawNotifications.value = unReadNotifications;
    changeValueNotifications(unReadNotifications);
  };
  const getNotificationsGroupById = data => {
    const notifications = orderByDate(data);
    const arr = [];
    const usedKeys = [];
    notifications.forEach(e => {
      if (!usedKeys.some(key => key === e.group_id)) {
        const coincidences = [];
        if (e.group_id) {
          usedKeys.push(e.group_id);
          notifications.forEach((item, index) => {
            const element = item;
            if (item.group_id === e.group_id) {
              element.index = index;
              coincidences.push(element);
            }
          });
        } else {
          coincidences.push(e);
        }
        arr.push(coincidences.length === 1 ? coincidences[0] : coincidences);
      }
    });
    return arr;
  };

  const orderByDate = data =>
    data.sort((a, b) => new Date(b.dispatchTime) - new Date(a.dispatchTime));

  onMounted(async () => {
    await api.getNotificationsList().then(res => {
      notifications.value = getNotificationsGroupById(res);
      rawNotifications.value = res;
    });
    await initListenerNotifications();
  });
  return {
    animated,
    hideAlert,
    notifications,
    notificationAlerts,
    removeNotification,
    activateRingAnimation,
    initListenerNotifications
  };
}
