<template>
  <div class="pt-notifications-menu">
    <PtDropdown ref="notificationsDropdown" @opened="onOpenDropDown">
      <template #opener>
        <button
          v-if="$refs.notificationsDropdown != undefined || notifications.length"
          class="flex items-center rounded focus:outline-none focus:shadow-outline text-gray"
        >
          <div
            class="relative bg-white rounded-full flex justify-center items-center p-1"
            :class="{
              'blue border-2 border-blue fill-icon': $refs.notificationsDropdown.open
            }"
          >
            <PtIcon
              name="pt-bell"
              class="text-2xl"
              :class="{
                'ring-animation': animated,
                'fill-icon__blue': $refs.notificationsDropdown.open
              }"
            />
            <span
              v-if="notifications?.length"
              class="w-3 h-3 absolute -right-px top-0 bg-red rounded-full"
            >
            </span>
            <div v-if="$refs.notificationsDropdown.open" class="pt-headerOverlay"></div>
          </div>
        </button>
      </template>
      <div class="notification-dropwdown-body">
        <div
          v-if="loaded && notifications?.length <= 0"
          class="flex items-center justify-center p-2 font-xs sm:font-sm relative 'h-20'"
        >
          <div class="flex items-center space-x-2">
            <PtIcon class="text-3xl" color="blue" name="pt-check-circle" />
            <span class="font-bold">{{ $t('notifications.empty') }}</span>
          </div>
        </div>
        <div v-else-if="loaded && notifications" class="notification-container pt-4 flex flex-col">
          <div class="flex flex-col space-y-7">
            <div v-for="(item, index) in notifications" :key="index" class="px-1">
              <div v-if="item !== null && item">
                <div
                  v-if="Array.isArray(item)"
                  class="notifications-collasible"
                  :class="
                    expandedNotification === index
                      ? 'showing'
                      : expandedNotification !== null
                      ? 'closed'
                      : 'static'
                  "
                >
                  <NotificationItem
                    v-for="(e, i) in item"
                    :key="e.index"
                    :index="e.index"
                    :item="e"
                    :group-length="item.length"
                    :show-collapsible-options="i === 0 && expandedNotification === index"
                    :is-collapsible-parent="i === 0"
                    :is-expanded="expandedNotification === index"
                    is-collapsible
                    class="notifications-collasible__item"
                    :class="
                      expandedNotification !== index &&
                      lastExpanded !== index &&
                      i !== 0 &&
                      'hidden'
                    "
                    @deleteAll="deleteAll"
                    @hideAll="hideAll"
                    @clickedNotification="clickNotification"
                    @openCollapsible="openCollapsible(index)"
                    @closeNotification="removeNotification"
                  />
                </div>
                <NotificationItem
                  v-else
                  :item="item"
                  :index="item.index"
                  @clickedNotification="clickNotification"
                  @closeNotification="removeNotification"
                />
              </div>
            </div>
          </div>
          <div class="inline-flex justify-end py-4">
            <div
              @click="goToEditProfile"
              class="text-blue font-bold flex items-center text-sm cursor-pointer"
            >
              <span class="hover:underline">{{ $t('notifications.managementNotifications') }}</span>
              <PtIcon class="text-xl" color="blue" name="pt-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </PtDropdown>
    <div class="fixed right-0 min-w-min z-20 w-full md:max-w-min">
      <div
        v-for="(type, index) in Object.keys(notificationAlerts)"
        :key="index"
        class="alert mt-2 mr-2 px-2 md:px-0"
      >
        <Alert
          v-show="notificationAlerts[type].length > 0"
          :type="type"
          :text="`<b>${getPropByType(type, 'text')}:</b> ${$t('notifications.newNotification', {
            counter: notificationAlerts[type].length
          })}`"
          @clickBody="openNotifications"
          @closeAlert="hideAlert(type)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Alert from './Alert';
import { watch, ref } from 'vue';
import NotificationItem from './Item';
import socket from '../../mixin/socket';
import { env, enums } from '@pt/env-config';
import { PtIcon, PtDropdown } from '@pt/designsystem';
export default {
  name: 'PtNotificationsNavigation',
  components: {
    NotificationItem,
    PtIcon,
    PtDropdown,
    Alert
  },

  setup() {
    const loaded = ref(false);
    const notificationsDropdown = ref(null);
    const expandedNotification = ref(null);
    const lastExpanded = ref(null);
    const notificationRoutes = {
      formacion: env.APP_BASE_DOMAIN + enums.COURSE_BASE_URL,
      herramientas: env.APP_TOOLS_BASE_URL
    };
    const { notifications, removeNotification, notificationAlerts, hideAlert, animated } = socket();

    watch(
      () => expandedNotification.value,
      newValue => {
        if (newValue !== -1 || newValue) {
          setTimeout(() => {
            lastExpanded.value = null;
          }, 500);
        }
      }
    );
    watch(
      () => notifications.value,
      newValue => {
        if (newValue !== -1 || newValue) {
          setTimeout(() => {
            lastExpanded.value = null;
          }, 500);
        }
      }
    );
    const openNotifications = () => {
      notificationsDropdown.value.openDropdown();
      expandedNotification.value = null;
      hideAlert(null, true);
    };
    const onOpenDropDown = state => {
      loaded.value = state;
    };
    const getPropByType = (type, prop) => {
      const types = {
        alert: { icon: 'pt-alert-triangle-filled', color: 'red', text: 'Alerta' },
        warning: { icon: 'pt-alert-circle-filled', color: 'orange', text: 'Aviso' },
        info: { icon: 'pt-info-circle-filled', color: 'blue', text: 'Información' },
        email: { icon: 'pt-info-circle-filled', color: 'blue', text: 'Información' }
      };
      return type && prop ? types[type][prop] : types.info[prop];
    };
    const getColorByType = type => {
      const colors = {
        alert: 'red',
        warning: 'orange',
        info: 'blue',
        email: 'blue'
      };
      return type ? colors[type] : colors.info;
    };

    const goToEditProfile = () => {
      window.location.replace(`${env.APP_BASE_DOMAIN}${enums.PROFILE_PAGE}`);
    };

    const clickNotification = notification => {
      if (notification.item.link) {
        goToNotification(notification.item);
      }
    };
    const goToNotification = async notification => {
      await removeNotification(notification);
      setTimeout(() => {
        const link =
          notification.link.type === 'absolute'
            ? notification.link.path
            : `${notificationRoutes[notification.link.type]}${notification.link.path}`;
        window.location.href = link;
      }, 100);
    };
    const deleteAll = item => {
      setTimeout(() => {
        removeNotification(item, true);
        expandedNotification.value = -1;
      }, 100);
    };
    const hideAll = () => {
      setTimeout(() => {
        lastExpanded.value = expandedNotification.value;
        expandedNotification.value = -1;
      }, 100);
    };
    const openCollapsible = index => {
      expandedNotification.value = index;
    };
    return {
      loaded,
      expandedNotification,
      lastExpanded,
      notificationRoutes,
      openNotifications,
      getPropByType,
      getColorByType,
      clickNotification,
      goToNotification,
      deleteAll,
      hideAll,
      openCollapsible,
      notifications,
      onOpenDropDown,
      notificationAlerts,
      removeNotification,
      goToEditProfile,
      hideAlert,
      notificationsDropdown,
      animated
    };
  }
};
</script>
<style src="./style.scss" lang="scss" />
